import React, { useEffect, useState } from "react";
import "./Login.scss";
import { Redirect } from "react-router-dom";
import { Row, Typography, Tabs, Form, Select } from "antd";
import { connect } from "react-redux";
import {
  authenticate,
  storeAccessToken,
} from "../../../store/modules/authentication/actions";
import config from "../../../config";
import TabContentLoginIsense from "./components/TabContentLoginIsense";
import TabContentLoginDokobit from "./components/TabContentLoginDokobit";
import TabContentLoginBasic from "./components/TabContentLoginBasic";
import TabContentLoginEIdEasy from "./components/TabContentLoginEIdEasy";
import { Option } from "antd/lib/mentions";
import TabContentLoginVP from "./components/TabContentLoginVP";

const { TabPane } = Tabs;
const { Title } = Typography;

const centered = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const Login = (props: any) => {
  const [countrySelected, setCountrySelected] = useState(
    localStorage.getItem("ecmrLoginCountry") || "lt"
  );
  const [tabSelected, setTabSelected] = useState(
    localStorage.getItem("loginTabKeySelected")
  );

  const onTabChange = (value) => {
    localStorage.setItem("loginTabKeySelected", value);
    setTabSelected(value);
  };

  const renderLoginForm = () => {
    return (
      <div>
        <Row style={centered}>
          <Title className="title" level={2}>
            <img
              className="logo"
              height="40px"
              src="https://sis.lt/wp-content/uploads/2021/04/Sign-sis.svg"
            />
            eCMR
          </Title>
          <img
            style={{
              width: "100%",
              maxWidth: "15rem",
              padding: "0 2rem 2rem 2rem",
            }}
            src="/img/login.svg"
          />
        </Row>
        <Row>
          <div className="countryWrap">
            <Form.Item label="eCMR Country">
              <Select
                defaultValue={countrySelected}
                onChange={(value: string) => {
                  localStorage.setItem("ecmrLoginCountry", value);
                  setCountrySelected(value);
                }}
                placeholder="Select country"
              >
                {config.countries.map((country) => (
                  <Option key={country.key} value={country.key}>
                    <img
                      alt={`${country.name} flag`}
                      style={{ height: "20px", marginRight: "0.25rem" }}
                      src={country.logo64}
                    />
                    {country.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Row>
        <Tabs
          defaultValue={localStorage.getItem("loginTabKeySelected") || ""}
          // defaultActiveKey={localStorage.getItem('loginTabKeySelected')}
          onChange={onTabChange}
          style={{ paddingBottom: "2rem" }}
          type="card"
        >
          <TabPane tab="Basic" key="1">
            <TabContentLoginBasic countrySelected={countrySelected} />
          </TabPane>
          {config.useDokobit && (
            <TabPane forceRender tab="Dokobit" key="2">
              <TabContentLoginDokobit
                isSelected={tabSelected === "2"}
                countrySelected={countrySelected}
              />
            </TabPane>
          )}
          {config.useIsenseLogin && (
            <TabPane forceRender tab="eID Isense" key="3">
              <TabContentLoginIsense countrySelected={countrySelected} />
            </TabPane>
          )}
          {config.useEIdEasy && (
            <TabPane forceRender tab="eID Easy" key="4">
              <TabContentLoginEIdEasy countrySelected={countrySelected} />
            </TabPane>
          )}
          {config.useVP && (
            <TabPane forceRender tab="VP" key="5">
              <TabContentLoginVP countrySelected={countrySelected} />
            </TabPane>
          )}
        </Tabs>
      </div>
    );
  };

  const { isLoggedIn } = props;

  return isLoggedIn ? (
    <Redirect from="/login" exact to={`${window.location.search}`} />
  ) : (
    <div className="Login">
      <div className="card">{renderLoginForm()}</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.loginReducer.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  authenticate: (country, username, password) =>
    dispatch(authenticate(country, username, password)),
  storeAccessToken: storeAccessToken(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
