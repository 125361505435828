import React, { useEffect, useState } from "react";
import './index.scss'
import {
    Row,
    Button,
    Avatar,
    Layout
} from "antd";
import { connect } from "react-redux";
import { logOut } from "../../../store/modules/authentication/actions";
import { getCurrentUserInfo } from "../../../services/indexRecord/indexRecord";
import {
    UserOutlined,
} from '@ant-design/icons';
const { Header } = Layout;

const NavigationBar = (props) => {
    const [jwtExpiration, setJwtExpiration] = useState<any>(null);
    useEffect(() => {
        const currentUser = getCurrentUserInfo()
        setInterval(() => {
            const expInSeconds = Math.floor((currentUser.exp * 1000 - Date.now()) / 1000)
            if (expInSeconds <= 0) {
                props.logOut()
            }
            const minutes = Math.floor(expInSeconds / 60)
            const seconds = expInSeconds - minutes * 60
            setJwtExpiration(expInSeconds <= 0 ? `0min 0s` : `${minutes}min ${seconds}s`)
        }, 1000)
    }, []);
    const currentUser = getCurrentUserInfo()
    return <Header>
        <Row justify="space-between" align="middle">
            <div>
                <img
                    height="32px"
                    src="https://sis.lt/wp-content/uploads/2021/04/Sign-sis.svg"
                />
            </div>
            <div className="userInfoAndActions" >
                {
                    currentUser &&
                    <div className="user">
                        <Avatar style={{ minWidth: "32px" }} icon={<UserOutlined />} />
                        <div className="userInfo">
                            <div className="userName">
                                {currentUser.fullName}
                            </div>
                            <div className="userId">
                                {currentUser.id}
                            </div>
                        </div>
                    </div>
                }
                <div className="jwtExpiration">
                    <div className="title">
                        Session expires in:
                    </div>
                    <div className="time">
                        {jwtExpiration}
                    </div>
                </div>
                <Button
                    type="dashed"
                    onClick={() => {
                        props.logOut();
                    }}
                >
                    Logout
                </Button>
            </div>
        </Row>
    </Header>
}

const mapStateToProps = (state) => ({
    login: state.loginReducer,
});

const mapDispatchToProps = (dispatch) => ({
    logOut: logOut(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);