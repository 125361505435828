import React, { useEffect } from "react";
import './TabContentLoginDokobit.scss';
import {
  Modal,
  Tooltip,
} from "antd";
import { connect } from "react-redux";
import { authenticate, storeAccessToken } from "../../../../../store/modules/authentication/actions";
import { dokobitCallback, getSessionToken } from "../../../../../services/dokobit";
import config from '../../../../../config'


const TabLoginDokobit = (props) => {

  useEffect(() => {
    requestDokobitSessionToken()
    setInterval(() => {
      if (!props.isLoggedIn && config.useDokobit && props.isSelected) {
        requestDokobitSessionToken()
      }
    }, 5 * 1000 * 60)
  }, [props.isSelected])

  const requestDokobitSessionToken = async () => {
    const { data: { session_token } } = await getSessionToken()
    const callBack = async () => {
      try {
        const { data: { username, password, country } } = await dokobitCallback(session_token, props.countrySelected)
        props.authenticate(country, username, password)
        // props.storeAccessToken(access_token, refresh_token, country)
      } catch (err) {
        console.log(err)
        Modal.error({
          centered: true,
          title: "Login failed!",
          content: (
            <div>
              {err.response && err.response.data
                ? err.response.data.error_description
                : err.toString()}
            </div>
          ),
        });
      }
    }
    var options = {
      sessionToken: session_token,
      callback: callBack
    }
    const temp: any = window
    temp.identityInit(options)
  }

  return <div className="TabContentLoginDokobit">
    <div className="container">
      {
        !props.countrySelected &&
        <Tooltip title="Select country">
          <div className="selectCountryTooltip"></div>
        </Tooltip>
      }
      <div className={`loginTab ${!props.countrySelected ? 'disable' : ''}`}>
        <div id="Dokobit-identity-container"></div>
      </div>
    </div>
  </div>
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.loginReducer.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  authenticate: (country, username, password) =>
    dispatch(authenticate(country, username, password)),
  storeAccessToken: storeAccessToken(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TabLoginDokobit);
