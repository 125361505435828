import React, { useState } from "react";
import {
  Card,
  Empty,
  Spin,
  Tooltip,
  Divider,
  Descriptions,
  Row,
  Collapse,
  Tag,
  Button,
} from "antd";
import { getCountryColor } from "../../utils/color";
import {
  FileTextOutlined,
  FileTextTwoTone,
  FlagOutlined,
  EditOutlined,
  CarOutlined,
} from "@ant-design/icons";
import './IndexDescription.scss';
import CertificateDisplay from "../CertificateDisplay/CertificateDisplay";
import StorageCollapse from "./components/StorageCollapse";

const { Panel } = Collapse;
const { Meta } = Card;


const IndexDescription = ({ loading, data, onEdit = null, onViewData }: any) => {
  const [selectedIndexes, setSelectedIndexes] = useState([])

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <Spin />
      </div>
    );
  }

  if (data == null || data.length === 0) {
    return <Empty />;
  }

  var isHistory = data[0].index !== undefined;

  const defaultKey = isHistory
    ? new Date(data[0].timestamp * 1000).toISOString()
    : data[0].documentIdentifier.value;

  const descriptionsStyle = { column: 1 };
  const dividerStyle: any = {
    orientation: "left",
    style: {
      color: "#333",
      fontWeight: "bold",
    },
  };



  // const requestAccess = (index) => {
  //   localStorage.setItem(
  //     index.document.documentIdentifier.value,
  //     JSON.stringify({ accessRequested: true })
  //   )
  // }
  const getActions = (indexData): any => isHistory
    ? null
    : [
      <Tooltip title="View Data">
        <FileTextOutlined
          key="view"
          onClick={() => onViewData(indexData)}
        />
      </Tooltip>,
    ]

  return (
    <div className="IndexDescription">
      <Collapse onChange={(selectedKeys: any) => setSelectedIndexes(selectedKeys)} defaultActiveKey={[defaultKey]} style={{ width: "100%" }}>
        {data.map((indexData) => {
          let indexState: any = {}
          let id: any = null
          let ts: any = null

          id = isHistory
            ? indexData.index.documentIdentifier.value
            : indexData.documentIdentifier.value;
          if (isHistory) {
            indexState = indexData;
            indexData = indexState?.index;
            ts = new Date(indexState?.timestamp * 1000).toISOString();
          }

          return (
            <Panel
              header={isHistory ? ts : id}
              key={isHistory ? ts : id}
              style={{ width: "100%" }}
              extra={
                <>
                  {isHistory ? (
                    <Tag
                      color={getCountryColor(
                        indexState.tx.creator.mspid.substring(0, 2)
                      )}
                    >
                      {indexState.tx.creator.mspid.substring(0, 2)}
                    </Tag>
                  ) : (
                    <></>
                  )}
                  {onEdit ? (
                    <Button
                      onClick={(event) => {
                        event.stopPropagation();
                        onEdit(indexData);
                      }}
                      icon={<EditOutlined />}
                    >
                      Edit
                    </Button>
                  ) : (
                    <></>
                  )}
                </>
              }
            >
              <Card
                actions={
                  getActions(indexData)
                }
              >
                <Meta
                  avatar={<FileTextTwoTone twoToneColor="#eb2f96" />}
                  title={indexData.documentIdentifier.value}
                />
                <Divider />
                {isHistory ? (
                  <>
                    <Divider {...dividerStyle}>Transaction</Divider>
                    <CertificateDisplay pem={indexState.tx.creator.certificate} />
                  </>
                ) : (
                  <div />
                )}
                <Divider {...dividerStyle}>Participant Identifier</Divider>
                <Descriptions {...descriptionsStyle} size="small" bordered>
                  <Descriptions.Item label="Scheme">
                    {indexData.participantIdentifier.scheme}
                  </Descriptions.Item>
                  <Descriptions.Item label="Value">
                    {indexData.participantIdentifier.value}
                  </Descriptions.Item>
                </Descriptions>
                <Divider {...dividerStyle}>Document Identifier</Divider>
                <Descriptions {...descriptionsStyle} size="small" bordered>
                  <Descriptions.Item label="Scheme">
                    <i>ID:</i> {indexData.documentIdentifier.scheme.id}
                    <br />
                    <i>Value:</i> {indexData.documentIdentifier.scheme.value}
                    <br />
                  </Descriptions.Item>
                  <Descriptions.Item label="Value">
                    {indexData.documentIdentifier.value}
                  </Descriptions.Item>
                </Descriptions>
                <Divider {...dividerStyle}>Process Identifier</Divider>
                <Descriptions {...descriptionsStyle} size="small" bordered>
                  <Descriptions.Item label="Scheme">
                    {indexData.processIdentifier.scheme}
                  </Descriptions.Item>
                  <Descriptions.Item label="Value">
                    {indexData.processIdentifier.value}
                  </Descriptions.Item>
                </Descriptions>
                <Divider {...dividerStyle} orientation="left">
                  Document
                </Divider>
                <Descriptions {...descriptionsStyle} size="small" bordered>
                  <Descriptions.Item label="Created At">
                    {indexData.document.createdDateTime}
                  </Descriptions.Item>
                  {
                    indexData.document.updatedDateTime &&
                    <Descriptions.Item label="Updated At">
                      {indexData.document.updatedDateTime}
                    </Descriptions.Item>
                  }
                  {
                    indexData.document.expiredDateTime &&
                    <Descriptions.Item label="Expired At">
                      {indexData.document.expiredDateTime}
                    </Descriptions.Item>
                  }
                  <Descriptions.Item label="Country Code">
                    <Tag
                      color={getCountryColor(indexData.document.countryCode)}
                      icon={<FlagOutlined />}
                    >
                      {indexData.document.countryCode}
                    </Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Fingerprint">
                    {indexData.document.fingerprint}
                  </Descriptions.Item>
                  <Descriptions.Item label="URI">
                    {indexData.document.uri}
                  </Descriptions.Item>
                  <Descriptions.Item label="Notes">
                    {
                      indexData.document.notes &&
                      <pre>
                        {JSON.stringify(JSON.parse(indexData.document.notes), null, 2) || '-'}
                      </pre>
                    }
                  </Descriptions.Item>
                </Descriptions>
                <StorageCollapse
                  activeKeys={[defaultKey, ...selectedIndexes]}
                  collapseKey={isHistory ? ts : id}
                  index={indexData} />
                <Divider {...dividerStyle}>Transport</Divider>
                <Row {...{ type: "flex", align: "middle", justify: "center" }}>
                  {indexData.document.transport ? (
                    indexData.document.transport.map((transport) => (
                      <Card
                        key={transport.registrationNumber}
                        style={{ marginLeft: 8 }}
                        type="inner"
                        size="small"
                        title={transport.registrationNumber}
                      >
                        <div>
                          <Tag
                            color={getCountryColor(transport.countryCode)}
                            icon={<FlagOutlined />}
                          >
                            {transport.countryCode}
                          </Tag>
                          <Tag icon={<CarOutlined />}>
                            {transport.categoryCode}
                          </Tag>
                        </div>
                      </Card>
                    ))
                  ) : (
                    <div />
                  )}
                </Row>
              </Card>
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default IndexDescription;
