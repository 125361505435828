import { instance } from './index'

export interface IAccessBody {
  userID: string,
  createdAt?: string,
  policies?: any[],
  indexId?: string,
  country?: string
}

export interface IRemoveAccessBody {
  userID: string,
  actions?: string[],
  indexId?: string
}

export interface IUpdateAccessBody {
  policies: any[],
  ndexKey?: string,
  indexId?: string,
  country?: string
}

export const createFileAccess = async (fileID: string, body: IAccessBody) => {
  return await instance.post(`/files/${fileID}/access`, body)
}

export const getFileAccess = async (fileID: string, includePolicies = true) => {
  return await instance.get(`/files/${fileID}/access${includePolicies ? '?includePolicies=true' : ''}`)
}

export const deleteFileAccess = async (fileID: string, userID: string, indexId: string, country: string) => {
  return await instance.delete(`/files/${fileID}/access/${userID}?indexId=${indexId}&country=${country}`)
}

export const updateFileAccess = async (fileID: string, userID: string, body: IUpdateAccessBody) => {
  return await instance.put(`/files/${fileID}/access/${userID}`, body)
}

export const getAllAccessibleFiles = async (lastModifiedBefore: string, lastModifiedAfter: string) => {
  const properties = { lastModifiedBefore, lastModifiedAfter }
  const params = Object.entries(properties)
    .filter(([_, value]) => !!value)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
  return await instance.get(`/access/files${!!params ? `?${params}` : ''}`)
}
