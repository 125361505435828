import React, { useEffect } from "react";
import "./TabContentLoginVP.scss";
import { Modal, Form, Button } from "antd";
import { connect } from "react-redux";
import {
  authenticate,
  storeAccessToken,
} from "../../../../../store/modules/authentication/actions";
import { externalUserLogin } from "../../../../../services/keycloak";
import LoaderWithBackdrop from "../../../../components/LoaderWithBackdrop";

const TabLoginVP = (props) => {
  const [loading, setLoading] = React.useState(false);

  const authenticate = async (userData) => {
    const { data } = await externalUserLogin(userData, props.countrySelected);
    if (!data.username) {
      Modal.error({
        centered: true,
        title: "Something went wrong",
        content: <div>Authentication failed</div>,
        onOk: () => {
          window.location.href = window.location.href.split("?")[0];
        },
      });
    } else {
      const { country, username, password } = data;
      props.authenticate(country, username, password);
    }
  };

  const callbackTest = (credentialSubject) => {
    if (credentialSubject) {
      const { familyName, givenName, id, email } = credentialSubject;

      const data = {
        code: id,
        firstName: givenName,
        lastName: familyName,
        email,
      };

      authenticate(data).then(() => {
        setLoading(false);
      });
    } else {
      Modal.error({
        centered: true,
        title: "Error",
        content: "Verifiable Credential not found",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    const temp: any = window;
    temp.corposignDidInit({ callback: callbackTest, width: 300, height: 300 });
  }, []);

  return (
    <div className="TabContentLoginVP">
      {loading && <LoaderWithBackdrop text="Loading..." />}
      <div id="corposign-did-container"></div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.loginReducer.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  authenticate: (country, username, password) =>
    dispatch(authenticate(country, username, password)),
  storeAccessToken: storeAccessToken(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabLoginVP);
