import React, { useState } from "react";
import {
    Input,
    Modal,
    Form,
    message,
} from 'antd';
import './index.scss';
import { connect } from "react-redux";
import { updateUser } from "../../../services/keycloak";
import { getCurrentUserInfo } from "../../../services/indexRecord";
import { refreshToken, logOut } from "../../../store/modules/authentication/actions";

const ModalMissingEmail = (props) => {
    const [isUpdatingUserEmail, setIsUpdatingUserEmail] = useState(false)
    const [form] = Form.useForm();
    const updateUserEmail = async (data) => {
        const { sub, country } = getCurrentUserInfo();
        setIsUpdatingUserEmail(true);

        try {
            await updateUser(sub, { email: data.email }, country)
            props.refreshToken(country);
        } catch (err) {
            Modal.error({
                centered: true,
                title: "Email update failed",
                content: <div>{err?.response?.data?.errorMessage || err.message}</div>,
            });
        }
        
        setIsUpdatingUserEmail(false);
    }
    return (
        <Modal
            className="ModalMissingEmail"
            title="You have missing email"
            centered
            visible={props.visible}
            onOk={form.submit}
            okText="Submit"
            okButtonProps={{ loading: isUpdatingUserEmail }}
            cancelButtonProps={{ disabled: isUpdatingUserEmail }}
            maskClosable={false}
            closable={false}
            onCancel={() => {
                form.resetFields();
                props.logout(true)
            }} >
            <div>
                <p>Please enter your email to complete your profile:</p>
                <Form
                    style={{ width: "100%" }}
                    onFinish={updateUserEmail}
                    form={form}
                >
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Please enter email",
                            },
                            {
                                type: 'email',
                                message: "Not valid email",
                            }
                        ]}
                    >
                        <Input
                            name="email"
                            placeholder="Email"
                            disabled={isUpdatingUserEmail}
                        />
                    </Form.Item>
                    <Form.Item
                        name="email-repeat"
                        key={form.getFieldValue('email')}
                        rules={[
                            {
                                required: true,
                                message: "Please repeat email",
                            },
                            {
                                type: 'email',
                                message: "Not valid email",
                            },
                            {
                                pattern: new RegExp(form.getFieldValue('email')),
                                message: "Value should match email",
                            }
                        ]}
                    >
                        <Input
                            name="email-repeat"
                            placeholder="Repeat email"
                            disabled={isUpdatingUserEmail}
                        />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
}

const mapDispatchToProps = (dispatch) => ({
    refreshToken: (country) =>
        dispatch(refreshToken(country)),
    logout: logOut(dispatch)
});

export default connect(null, mapDispatchToProps)(ModalMissingEmail);