import * as React from 'react'
import { Avatar, Dropdown, Menu, Spin } from 'antd';
import {
    UserOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import './index.scss';
import { message } from 'antd';
import { updateFileAccess } from '../../../../../services/cloudStorage';
import { equals } from 'rambda';
import { getCurrentUserId } from '../../../../../services/indexRecord/indexRecord';
import { getAccessOptions } from '../../../../utils/fileAccess';



export default class PermissionCell extends React.Component<any, any> {
    state: any = {
        loading: false,
        item: this.props.item
    };

    accessOptions: any = getAccessOptions(this.props.items[0].userID)

    componentDidMount() {
        this.setState({
            item: this.props.item
        })
    }

    onChange = list => {
        this.setState({
            userActions: list
        })
    };

    submitAccess = async (userAccess) => {
        const { fileId, item: { userID } } = this.props
        this.setState({
            loading: true
        })

        const access = {
            indexId: this.props.indexId,
            country: this.props.indexCountry,
            policies: userAccess.access
                .map((item) => ({
                    "description": `${item.source} ${item.type} policy`,
                    "actions": item.actions,
                    "resources": item.scope ? item.scope
                        .map(scopeItem => `sis:storage:files:${fileId}:${item.source}:${scopeItem}`) :
                        [
                            `sis:storage:files:${fileId}:${item.source}`
                        ],
                    "effect": item.type
                })),
        }


        this.setState({
            item: {
                ...this.state.item,
                policies: access.policies

            }
        })

        try {
            await updateFileAccess(this.props.fileId, userID, access)
            message.success({
                content: "File access successfully updated",
                duration: 3,
            });
        } finally {
            this.setState({
                isAccessManagementVisible: false,
                loading: false
            })
        }
    }


    render() {
        const { currentUserAccess } = this.props
        const user = this.state.item

        const findObject = (items, searchKey) => {
            return (items
                .find(({ description }) => new RegExp(searchKey, 'i').test(description)) || {})
        }

        const selectAccessType = async (e) => {
            this.setState({ loading: true })
            if (e.key === 'remove') {
                const { item: { userID } } = this.props
                await this.props.removeAccess(userID)
            } else {
                const newUserAccess = this.accessOptions
                    .find(({ key }) => key === e.key)
                await this.submitAccess(newUserAccess)
            }
            this.setState({ loading: false })
        }

        const getCurrentAccess = () => ({
            metadata: findObject(user.policies, 'metadata').actions || [],
            content: findObject(user.policies, 'content').actions || [],
            access: findObject(user.policies, 'access').actions || [],
        })

        const getCurrentUserAccessType = (): any => {
            const userAccess = getCurrentAccess()
            return this.accessOptions.find(({ access }) => {
                return equals(access.find(({ source }) => source === 'metadata').actions, userAccess.metadata) &&
                    equals(access.find(({ source }) => source === 'content').actions, userAccess.content) &&
                    equals(access.find(({ source }) => source === 'access').actions, userAccess.access)
            }) || {}
        }

        const isOwnerCell = user.policies
            .flatMap(({ actions }) => actions)
            .indexOf('<.*>') > -1

        const isOwner = currentUserAccess.accessActions.indexOf('<.*>') > -1
        const isCurrentUserCell = user.userID === getCurrentUserId()

        const canEditAccess = (isOwner ||
            (currentUserAccess.accessActions &&
                currentUserAccess.accessActions.indexOf('update') > -1)) &&
            !isCurrentUserCell

        const menu = (
            <Menu onClick={selectAccessType}>
                {
                    this.accessOptions.map(({ key, label, icon }) => (
                        <Menu.Item key={key} icon={icon}>
                            {label}
                        </Menu.Item>
                    ))
                }
                <Menu.Divider />
                <Menu.Item style={{ color: '#f5222d' }} key={'remove'} icon={<CloseOutlined />}>
                    {"Remove"}
                </Menu.Item>
            </Menu>
        );

        return (
            <div className="PermissionCell">
                <div className={`cell`} >
                    <div className="userInfo">
                        <div style={{ width: "32px" }}>
                            <Avatar icon={<UserOutlined />} />
                        </div>
                        <div className="userMeta">
                            <div className="userName" >{user.fullName}</div>
                            <div className="userId" >{user.userID}</div>
                        </div>
                    </div>
                    {
                        this.state.loading ?
                            <div className="accessSpinner">
                                <Spin size={"small"} />
                            </div> :
                            !isOwnerCell &&
                            <Dropdown.Button
                                disabled={!canEditAccess}
                                overlay={menu}
                                icon={getCurrentUserAccessType().icon}
                            />
                    }
                </div>
            </div>
        );
    }
}