import React, { useEffect, useState } from "react";
import './Home.scss'
import {
  Col,
  Card,
  Tabs,
  Modal,
  Row,
  Button,
  Layout,
  message,
  Collapse,
} from "antd";

import config from '../../../config';
import { logOut } from "../../../store/modules/authentication/actions";
import { connect } from "react-redux";
import FindIndexTab from "../../components/FindIndexTab/FindIndexTab";
import GetIndexTab from "../../components/GetIndexTab";
import GetAccessLogTab from "../../components/GetAccessLogTab";
import { PlusOutlined } from "@ant-design/icons";
import IndexDrawerForm from "../../components/Drawers/IndexDrawerForm/IndexDrawerForm";
import NavigationBar from "../../components/NavigationBar";
import { addDocumentIndexAction, updateDocumentIndexAction } from "../../../store/modules/documentIndex/actions";
import { getFile, getFileAccess } from "../../../services/cloudStorage";
import FileDescription from "../../components/FileDescription";
import ModalMissingEmail from "../../components/ModalMissingEmail";
import { getCurrentUserInfo } from "../../../services/indexRecord";
import valuesToIndex from "./valuesToIndex";
import Stats from "./components/Stats/Stats";

const { Content, Footer } = Layout;
const { Panel } = Collapse

const Home = (props) => {
  const [addIndexVisible, setAddIndexVisible] = useState<any>(false);
  const [creatingIndex, setCreatingIndex] = useState<any>(false);
  const [document, setDocument] = useState<any>(null);
  const [loadingDocument, setLoadingDocument] = useState<any>(false);
  const [indexDrawerData, setIndexDrawerData] = useState<any>(null);

  useEffect(() => {
    const url = new URL(window.location.href)
    const documentId = url.searchParams.get('documentId')
    if (documentId) {
      getDocument(documentId)
    }
  }, []);


  const getDocument = async (documentId) => {
    try {
      setLoadingDocument(true)
      const access = await getFileAccess(documentId)
      const fileMeta = await getFile(documentId)
      setDocument({
        access: access.data,
        metaData: fileMeta.data
      })
      setLoadingDocument(false)
    } catch (err) {
      setLoadingDocument(false)
      console.log(err)
      Modal.error({
        centered: true,
        title: "Not found",
        content: <div>Document with id {documentId} not found</div>,
      });
    }
  }

  const openIndexDrawer = (index = null) => {
    setAddIndexVisible(true)
    setIndexDrawerData(index)
    return index
  };

  const updateCurrentIndex = async (values) => {
    setCreatingIndex(true)

    var index = valuesToIndex(values);

    try {
      await props.updateDocumentIndex(props.login.country, index)

      message.success({
        content: "Index successfully updated!",
        duration: 3,
      });
      setCreatingIndex(false)
      setAddIndexVisible(false)
    } catch (err) {
      setCreatingIndex(false)
      var error = err.toString();

      if (err.response && err.response.status === 401) {
        Modal.error({
          centered: true,
          title: "Session ended",
          content: <div>Your session has ended. Please login again.</div>,
          onOk: () => {
            window.location.reload();
          },
        });
        return;
      }

      if (err.response && err.response.data) {
        error = err.response.data.message ? err.response.data.message : err.response.data.response;
      }
      Modal.error({
        centered: true,
        title: "Index update failed!",
        content: <div>
          {
            err.response && err.response.data ?
              <Collapse className="errorExpandable">
                <Panel header={error} key="1">
                  <pre>
                    <code>
                      {JSON.stringify(err.response.data, null, 2)}
                    </code>
                  </pre>
                </Panel>
              </Collapse>
              :
              error
          }
        </div>,
        onOk: () => {
          setCreatingIndex(false)
          setAddIndexVisible(false)
        },
      });
      return;
    }
  };

  const submitNewIndex = async (values) => {
    setCreatingIndex(true)
    var index = valuesToIndex(values);
    try {
      await props.addDocumentIndex(props.login.country, index)
      message.success({
        content: "Index successfully created!",
        duration: 3,
      });
      setCreatingIndex(false)
      setAddIndexVisible(false)

      return
    } catch (err) {
      setCreatingIndex(false)
      var error = err.toString();

      if (err.response && err.response.status == 401) {
        Modal.error({
          centered: true,
          title: "Session ended",
          content: <div>Your session has ended. Please login again.</div>,
          onOk: () => {
            window.location.reload();
          },
        });
        return;
      }

      if (err.response && err.response.data) {
        error = err.response.data.message ? err.response.data.message : err.response.data.response;
      }
      Modal.error({
        centered: true,
        title: "Index creation failed!",
        content: <div>
          {
            err.response && err.response.data ?
              <Collapse className="errorExpandable">
                <Panel header={error} key="1">
                  <pre>
                    <code>
                      {JSON.stringify(err.response.data, null, 2)}
                    </code>
                  </pre>
                </Panel>
              </Collapse>
              :
              error
          }
        </div>,
        onOk: () => {
          setCreatingIndex(false)
          setAddIndexVisible(false)
        },
      });
      return;
    }
  };

  const getTabs = () => {
    const tabs = [
      <GetIndexTab
        tab="Get Index"
        key="get"
        withHistory={props.login.memberType === "government"}
        onEdit={
          props.login.memberType === "business"
            ? (data) => openIndexDrawer(data)
            : () => { }
        }
      />,
    ];

    if (props.login.memberType === 'government') {
      tabs.unshift(
        <FindIndexTab
          tab="Find Index"
          key="find"
          onEdit={(data) => openIndexDrawer(data)}
        />
      );
      tabs.push(<GetAccessLogTab tab="View Logs" key="log" />)
    }

    return tabs;
  };


  const closeDrawer = () => {
    setAddIndexVisible(false)
  }

  const url = new URL(window.location.href)
  const indexId = url.searchParams.get('indexId')
  const defaultTabKey = (props.login.memberType === "government" && indexId == null) ? 'find' : 'get'

  return (
    <Layout className={"Home"}>
      <ModalMissingEmail visible={!getCurrentUserInfo().email} />
      <Layout className="site-layout">
        <NavigationBar />
        <Content style={{ margin: "0 16px" }}>
          <div className="site-layout-background" style={{ padding: 24 }}>
            <Row justify="center">
              {
                (document || loadingDocument) ?
                  <Col>
                    <Card className="mainCard" >
                      <Row justify="center">
                        <FileDescription
                          data={document}
                          loading={loadingDocument}
                        />
                      </Row>
                    </Card>
                  </Col>
                  :
                  <Col>
                    <Row justify="center">
                      <Stats login={props.login} />
                    </Row>
                    <Row justify="center">
                      <Card className="mainCard" >
                        <Row justify="center">
                          {props.login.memberType === "business" ? (
                            <Button
                              type="primary"
                              shape="round"
                              onClick={() => openIndexDrawer(null)}
                            >
                              <PlusOutlined /> Add new index
                            </Button>
                          ) : (
                            <></>
                          )}
                        </Row>
                        <Row justify="center">
                          <Tabs
                            defaultActiveKey={defaultTabKey}
                            centered>
                            {getTabs()}
                          </Tabs>
                        </Row>
                      </Card>
                    </Row>
                  </Col>
              }
            </Row>
          </div>
        </Content>

        {props.login.memberType === "business" ? (
          <IndexDrawerForm
            visible={addIndexVisible}
            loading={creatingIndex}
            onClose={closeDrawer}
            onSubmit={
              indexDrawerData ? updateCurrentIndex : submitNewIndex
            }
            indexData={indexDrawerData}
            title={indexDrawerData ? `Update document index` : null}
          />
        ) : (
          <></>
        )}
        <Footer className={"copyright"} >
          ChainRecord ©{new Date().getFullYear()}
          <div className="version">{`${config.projectCommitDate} ${config.projectCommitShortSHA}`}</div>
        </Footer>
      </Layout>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    login: state.loginReducer,
    indexData: state.documentIndexReducer.indexData
  }
};

const mapDispatchToProps = (dispatch) => ({
  logOut: () => logOut(dispatch),
  updateDocumentIndex: updateDocumentIndexAction(dispatch),
  addDocumentIndex: addDocumentIndexAction(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
