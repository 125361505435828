export enum ActionTypes {
    REQUEST_GET_FILE_ACCESS = "@@documents/REQUEST_GET_FILE_ACCESS",
    SUCCESS_GET_FILE_ACCESS = "@@documents/SUCCESS_GET_FILE_ACCESS",
    ERROR_GET_FILE_ACCESS = "@@documents/ERROR_GET_FILE_ACCESS",

    REQUEST_GET_FILE_METADATA = "@@documents/REQUEST_GET_FILE_METADATA",
    SUCCESS_GET_FILE_METADATA = "@@documents/SUCCESS_GET_FILE_METADATA",
    ERROR_GET_FILE_METADATA = "@@documents/ERROR_GET_FILE_METADATA",

    REQUEST_GET_DOCUMENT_INDEX = "@@documents/REQUEST_GET_DOCUMENT_INDEX",
    SUCCESS_GET_DOCUMENT_INDEX = "@@documents/SUCCESS_GET_DOCUMENT_INDEX",
    ERROR_GET_DOCUMENT_INDEX = "@@documents/ERROR_GET_DOCUMENT_INDEX",

    REQUEST_FIND_DOCUMENT_INDEX = "@@documents/REQUEST_FIND_DOCUMENT_INDEX",
    SUCCESS_FIND_DOCUMENT_INDEX = "@@documents/SUCCESS_FIND_DOCUMENT_INDEX",
    ERROR_FIND_DOCUMENT_INDEX = "@@documents/ERROR_FIND_DOCUMENT_INDEX",

    REQUEST_ADD_DOCUMENT_INDEX = "@@documents/REQUEST_ADD_DOCUMENT_INDEX",
    SUCCESS_ADD_DOCUMENT_INDEX = "@@documents/SUCCESS_ADD_DOCUMENT_INDEX",
    ERROR_ADD_DOCUMENT_INDEX = "@@documents/ERROR_ADD_DOCUMENT_INDEX",

    REQUEST_UPDATE_DOCUMENT_INDEX = "@@documents/REQUEST_UPDATE_DOCUMENT_INDEX",
    SUCCESS_UPDATE_DOCUMENT_INDEX = "@@documents/SUCCESS_UPDATE_DOCUMENT_INDEX",
    ERROR_UPDATE_DOCUMENT_INDEX = "@@documents/ERROR_UPDATE_DOCUMENT_INDEX",

    REQUEST_CLEAR_DOCUMENT_INDEX = "@@documents/REQUEST_CLEAR_DOCUMENT_INDEX",
    SUCCESS_CLEAR_DOCUMENT_INDEX = "@@documents/SUCCESS_CLEAR_DOCUMENT_INDEX",
    ERROR_CLEAR_DOCUMENT_INDEX = "@@documents/ERROR_CLEAR_DOCUMENT_INDEX",
}

export interface DocumentAction {
    type: ActionTypes,
    params?: any[],
    data?: any,
    error?: Error
}

export interface DocumentState {
    loading: boolean,
    indexData?: any,
    error?: any
}


