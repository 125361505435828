import React, { useState } from 'react'
import './CertificateDisplay.scss'
import {
  Card,
  Divider,
  Row,
  Col,
  Tag,
  Switch
} from 'antd'
import { Certificate } from '@fidm/x509'
const dividerProps = { style: { margin: 4 } }
const row: any = { align: 'middle' }

const CertificateDisplay = ({ pem }) => {
  const [showParsed, setShowParsed] = useState(true)

  const cert = Certificate.fromPEM(Buffer.from(pem, 'utf-8'))

  let blockchainExtension = cert.extensions.find(
    (ext) => ext.oid === '1.2.3.4.5.6.7.8.1'
  )

  if (blockchainExtension !== undefined) {
    blockchainExtension = JSON.parse(blockchainExtension.value.toString())
      .attrs
  }

  const onParsedSwitchChange = (enabled) => {
    setShowParsed(enabled)
  }

  return (
    <div className="CertificateDisplay">
      <Card
        type="inner"
        title={showParsed ? 'Certificate properties' : 'Certificate'}
        size="small"
        extra={
          <Switch
            checkedChildren="Parsed"
            unCheckedChildren="Raw"
            defaultChecked
            onChange={onParsedSwitchChange}
          />
        }
      >
        {showParsed ? (
          <div>
            <Row {...row}>
              <Col span={4}>
                <b>Subject:</b>
              </Col>
              <Col span={20}>
                CN={cert.subject.commonName}, OU=
                {cert.subject.organizationalUnitName}
              </Col>
            </Row>
            <Divider {...dividerProps} />
            <Row {...row}>
              <Col span={4}>
                <b>Issuer:</b>
              </Col>
              <Col span={20}>
                C={cert.issuer.countryName}, ST=
                {cert.issuer.localityName}, O={cert.issuer.organizationName},
                OU=
                {cert.issuer.organizationalUnitName}, CN=
                {cert.issuer.commonName}
              </Col>
            </Row>
            <Divider {...dividerProps} />
            <Row {...row}>
              <Col span={4}>
                <b>Valid From:</b>
              </Col>
              <Col span={20}>{cert.validFrom.toISOString()}</Col>
            </Row>
            <Divider {...dividerProps} />
            <Row {...row}>
              <Col span={4}>
                <b>Valid To:</b>
              </Col>
              <Col span={20}> {cert.validTo.toISOString()}</Col>
            </Row>
            <Divider {...dividerProps} />
            <Row {...row}>
              <Col span={4}>
                <b>Serial number:</b>
              </Col>
              <Col span={20}> {cert.serialNumber}</Col>
            </Row>
            <Card
              type="inner"
              title="Blockchain Extension Attributes"
              size="small"
              style={{ marginTop: 8 }}
            >
              <Row {...row}>
                <Col span={4}>
                  <b>Authority ICD:</b>
                </Col>
                <Col span={20}> {blockchainExtension?.authority_icd}</Col>
              </Row>
              <Divider {...dividerProps} />
              <Row {...row}>
                <Col span={4}>
                  <b>Authority ID:</b>
                </Col>
                <Col span={20}> {blockchainExtension?.authority_id}</Col>
              </Row>
              <Divider {...dividerProps} />
              <Row {...row}>
                <Col span={4}>
                  <b>Organization ID:</b>
                </Col>
                <Col span={20}> {blockchainExtension?.organization_id}</Col>
              </Row>
              <Divider {...dividerProps} />
              <Row {...row}>
                <Col span={4}>
                  <b>Organization Name:</b>
                </Col>
                <Col span={20}> {blockchainExtension?.organization_name}</Col>
              </Row>
              <Divider {...dividerProps} />
              <Row {...row}>
                <Col span={4}>
                  <b>Member Type:</b>
                </Col>
                <Col span={20}>
                  <Tag
                    color={
                      blockchainExtension?.member_type === 'government'
                        ? 'blue'
                        : 'volcano'
                    }
                  >
                    {blockchainExtension?.member_type}
                  </Tag>
                </Col>
              </Row>
            </Card>
          </div>
        ) : (
          <span className={'pem'}>{pem}</span>
        )}
      </Card>
    </div>
  )
}

export default CertificateDisplay
