import React, { useState } from "react";
import './TabContentLoginIsense.scss';
import {
  Modal,
  Button,
  Select,
} from "antd";
import { connect } from "react-redux";
import { authenticate, storeAccessToken } from "../../../../../store/modules/authentication/actions";
import config from '../../../../../config'
import { getSessionToken } from "../../../../../services/isense";
import LoaderWithBackdrop from "../../../../components/LoaderWithBackdrop";

const { Option } = Select;


const TabLoginIsense = (props) => {
  const [isenseLoginStatus, setIsenseLoginStatus] = useState<any>(null)
  const [loginStatusCheckCount, setLoginStatusCheckCount] = useState(0)
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)

  const login = async () => {
    const { data: { sessionToken } } = await getSessionToken();
    setIsLoaderVisible(true)
    window.open(`https://api.isense.lt/?sessionToken=${sessionToken}`, '_blank');
    // checkStatus();
  }

  const checkStatus = () => {
    const interval = setInterval(async () => {
      setLoginStatusCheckCount(loginStatusCheckCount + 1)
      if (isenseLoginStatus && (isenseLoginStatus !== 'FINISHED' || loginStatusCheckCount > 20)) {
        const { data } = await isenseLoginStatus();
        setIsenseLoginStatus(data.sessionStatus)
        if (data.sessionStatus === "FINISHED" && data.identity == null) {
          setIsLoaderVisible(false)
          Modal.error({
            centered: true,
            title: "Something went wrong",
            content: <div>Authentication failed</div>,
          });
          return
        } else if (
          data.sessionStatus === "FINISHED" &&
          data.identity !== null
        ) {
          clearInterval(interval)
          setIsLoaderVisible(false)
          return
        }
      }
    }, 5 * 1000)
  }

  return <div className="TabContentLoginIsense">
    {
      isLoaderVisible &&
      <LoaderWithBackdrop text={"Loading"} />
    }
    <div className="container">
      <div className={`loginTab ${!props.countrySelected ? 'disable' : ''}`}>
        <Button
          type="primary"
          onClick={login}
          style={{ width: "100%", background: "#00BFA6", color: 'white' }}>
          Login with eID
        </Button>
      </div>
    </div>
  </div>
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.loginReducer.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  authenticate: (country, username, password) =>
    dispatch(authenticate(country, username, password)),
  storeAccessToken: storeAccessToken(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TabLoginIsense);
