import React from "react";
import './TabContentLoginBasic.scss';
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import {
  Modal,
  Form,
  Input,
  Button,
} from "antd";
import { connect } from "react-redux";
import { authenticate, storeAccessToken } from "../../../../../store/modules/authentication/actions";

const TabLoginIsense = (props: any) => {

  const onFinish = async (values) => {
    try {
      if (props.countrySelected == null) {
        Modal.error({
          centered: true,
          title: "Error",
          content: "You must select a country!",
        });
        return;
      }
      const { authenticate } = props
      await authenticate(
        props.countrySelected,
        values.username,
        values.password
      );
    } catch (err) {
      Modal.error({
        centered: true,
        title: "Login failed!",
        content: (
          <div>
            {err.response && err.response.data
              ? err.response.data.error_description
              : err.toString()}
          </div>
        ),
      });
      console.log("Error", err);
    }
  };

  const onFinishFailed = () => { };

  return <div className="TabContentLoginBasic">
    <Form
      name="basic"
      initialValues={{
        remember: true,
        country: localStorage.getItem('ecmrLoginCountry')
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      style={{ width: "100%", minWidth: "350px", }}
    >
      <Form.Item
        name="username"
        
        rules={[{ required: true, message: "Please input your username!" }]}
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Input
          style={{ display: "flex", width: "100%" }}
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Username"
        />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Input
          style={{ width: "100%" }}
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>

      <Form.Item
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}>
          Login
        </Button>
      </Form.Item>
    </Form>
  </div>
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.loginReducer.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  authenticate: (country, username, password) =>
    dispatch(authenticate(country, username, password)),
  storeAccessToken: storeAccessToken(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TabLoginIsense);
