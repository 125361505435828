import './Stats.scss';
import { Col, Divider, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getBlockchainInfo, getBlockInfo } from "../../../../../services/blockchain";
import StatsCard from "../../../../components/StatsCard";

const StatsCardCustom: any = StatsCard;

const Stats = (props) => {
    const [timer, setTimer] = useState<any>(null);
    const [blockchainInfo, setBlockchainInfo] = useState<any>(null);
    const [transactionInfo, setTransactionInfo] = useState<any>(null);

    const txInfoLoading = transactionInfo == null,
        bcInfoLoading = blockchainInfo == null;

    useEffect(() => {
        fetchStats();
        setTimer(setInterval(() => fetchStats(), 30000));
        return () => {
            clearInterval(timer);
            setTimer(null);
        }
    }, [])

    const fetchStats = async () => {

        if (props.login.memberType === "government") {
            try {
                const bcInfoResp = await getBlockchainInfo(props.login.country);
                var lastHeight = blockchainInfo
                    ? blockchainInfo?.height
                    : -1;
                setBlockchainInfo(bcInfoResp.data)

                if (lastHeight !== bcInfoResp.data.height) {
                    const resp = await getBlockInfo(
                        props.login.country,
                        bcInfoResp.data.height - 1
                    );
                    if (resp.status === 200) {
                        var lastIndexes: any = [];
                        resp.data.data.data.forEach((tx) => {
                            var actions = tx.payload.data.actions;
                            var indexes: any = [];
                            actions.forEach((action) => {
                                action.payload.action.proposal_response_payload.extension.results.ns_rwset.forEach(
                                    (set) => {
                                        let indexReads: any = set.rwset.reads.filter((read) =>
                                            read.key.endsWith("-log")
                                        );
                                        if (indexReads.length > 0) {
                                            indexReads = indexReads.map((read) =>
                                                read.key.slice(0, read.key.length - 4)
                                            );
                                            indexes.push(...indexReads);
                                        }
                                    }
                                );
                            });
                            lastIndexes.push(...indexes);
                        });

                        setTransactionInfo({
                            transactions: resp.data.data.data,
                            lastTransaction: {
                                mspid:
                                    resp.data.data.data[resp.data.data.data.length - 1].payload
                                        .header.signature_header.creator.mspid,
                                timestamp:
                                    resp.data.data.data[resp.data.data.data.length - 1].payload
                                        .header.channel_header.timestamp,
                            },
                            lastIndexes: lastIndexes,
                        })
                    }
                }
            } catch (err) {
                console.log("Failed to fecth stats: ", err);
            }
        }
    }

    if (props.login.memberType !== "government") {
        return <></>;
    }

    return (
        <>
            <StatsCardCustom
                loading={bcInfoLoading}
                title="Data blocks"
                tooltip="The total number of data blocks in blockchain network"
            >
                <Typography.Title level={3}>
                    {blockchainInfo ? blockchainInfo.height : -1}
                </Typography.Title>
            </StatsCardCustom>
            <StatsCardCustom
                loading={txInfoLoading}
                title="Last Transaction"
                tooltip="General information about last transaction submitted to the network"
            >
                <Row justify="start" align="middle">
                    <Typography.Title level={4}>
                        {transactionInfo
                            ? transactionInfo.lastTransaction.mspid
                            : ""}
                    </Typography.Title>
                </Row>
                <Divider dashed />
                <Row justify="start" align="middle">
                    <Typography.Title level={4}>
                        {transactionInfo
                            ? (() => {
                                var ts = new Date(
                                    transactionInfo.lastTransaction.timestamp
                                );
                                return (
                                    ts.toLocaleDateString() + " " + ts.toLocaleTimeString()
                                );
                            })()
                            : ""}
                    </Typography.Title>
                </Row>
            </StatsCardCustom>
            <StatsCardCustom
                loading={txInfoLoading}
                title="Last Indexes"
                tooltip="A list of all indexes that were read, created or updated in last transaction"
            >
                {transactionInfo
                    ? transactionInfo.lastIndexes.map((index) => (
                        <Row key={index}>
                            <Col span={24}>
                                <Typography.Text code>{index}</Typography.Text>
                            </Col>
                        </Row>
                    ))
                    : ""}
            </StatsCardCustom>
        </>
    );
}

export default connect()(Stats);