import React, { Component } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
const CustomMap: any = Map;
const containerStyle = {
  position: "relative",
  width: "55vh",
  height: "55vh",
};
export class MapContainer extends Component<any> {
  render() {
    const markerConfig: any = {
      name: "Location",
      position: {
        lat: this.props.location.latitude,
        lng: this.props.location.longitude
      }
    }
    return (
      <CustomMap
        containerStyle={containerStyle}
        google={this.props.google}
        {...{ zoom: 16 }}
        initialCenter={{
          lat: this.props.location.latitude,
          lng: this.props.location.longitude,
        }}
      >
        <Marker
          {...markerConfig} />
      </CustomMap>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCmy1-1a7xaf2R4EMZGYP7R-_Fz79IkgMs",
})(MapContainer);
