import { ActionTypes, DocumentAction, DocumentState } from "./types";

const initialState: any = { loading: false, indexData: null, findIndexDataResult: [], error: null };

const documentIndexReducers = (state = initialState, action: DocumentAction): DocumentState => {

    switch (action.type) {
        case ActionTypes.REQUEST_GET_DOCUMENT_INDEX:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.SUCCESS_GET_DOCUMENT_INDEX:
            return {
                ...state,
                indexData: action.data,
                loading: false,
                error: null
            }
        case ActionTypes.ERROR_GET_DOCUMENT_INDEX:
            return {
                ...state,
                indexData: null,
                error: action.error,
                loading: false,
            };
        case ActionTypes.REQUEST_GET_FILE_ACCESS:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.SUCCESS_GET_FILE_ACCESS:
            state.indexData = updateIndexData(
                state.indexData,
                action.params?.length && action.params[0],
                action.data,
                'access'
            )
            return {
                ...state,
                findIndexDataResult: updateFindIndexResultDocument(
                    state.findIndexDataResult,
                    action.params?.length && action.params[0],
                    { access: action.data }
                ),
                loading: false,
                error: null
            }
        case ActionTypes.ERROR_GET_FILE_ACCESS:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        case ActionTypes.REQUEST_GET_FILE_METADATA:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.SUCCESS_GET_FILE_METADATA:

            state.indexData = updateIndexData(
                state.indexData,
                action.params?.length && action.params[0],
                action.data,
                'fileMetadata'
            )

            return {
                ...state,
                findIndexDataResult: updateFindIndexResultDocument(
                    state.findIndexDataResult,
                    action.params?.length && action.params[0],
                    { fileMetadata: action.data }
                ),
                loading: false,
                error: null
            }
        case ActionTypes.ERROR_GET_FILE_METADATA:

            return {
                ...state,
                error: action.error,
                loading: false,
            };
        case ActionTypes.REQUEST_FIND_DOCUMENT_INDEX:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.SUCCESS_FIND_DOCUMENT_INDEX:
            return {
                ...state,
                findIndexDataResult: action.data,
                loading: false,
                error: null
            }
        case ActionTypes.ERROR_FIND_DOCUMENT_INDEX:
            return {
                ...state,
                findIndexDataResult: [],
                error: action.error,
                loading: false,
            };
        case ActionTypes.REQUEST_ADD_DOCUMENT_INDEX:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.SUCCESS_ADD_DOCUMENT_INDEX:
            return {
                ...state,
                // indexData: action.data,
                loading: false,
                error: null
            }
        case ActionTypes.ERROR_ADD_DOCUMENT_INDEX:
            return {
                ...state,
                indexData: null,
                error: action.error,
                loading: false,
            };
        case ActionTypes.REQUEST_UPDATE_DOCUMENT_INDEX:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.SUCCESS_UPDATE_DOCUMENT_INDEX:
            return {
                ...state,
                indexData: action.data,
                loading: false,
                error: null
            }
        case ActionTypes.ERROR_UPDATE_DOCUMENT_INDEX:
            return {
                ...state,
                indexData: null,
                error: action.error,
                loading: false,
            };
        case ActionTypes.REQUEST_CLEAR_DOCUMENT_INDEX:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.SUCCESS_CLEAR_DOCUMENT_INDEX:
            return {
                ...state,
                indexData: null,
                loading: false,
                error: null
            }
        case ActionTypes.ERROR_CLEAR_DOCUMENT_INDEX:
            return {
                ...state,
                indexData: null,
                error: action.error,
                loading: false,
            };
        default:
            return state;
    }
};

const updateIndexData = (indexData, documentId, actionData: any, property) => {
    if (indexData && Array.isArray(indexData)) {
        return indexData
            .map(index => {
                if (index.index.document.uri.indexOf(documentId) > -1) {
                    index.index.document[property] = actionData
                }
                return index
            })
    } else if (indexData && checkDocumentUriAndIdMatch(indexData, documentId)) {
        indexData.document[property] = actionData
        return indexData
    }
}

const updateFindIndexResultDocument = (findIndexDataResult, fileId, override) => {
    const result = findIndexDataResult
        .map((index) => {
            if (index.document.uri.indexOf(fileId) > -1) {
                return { ...index, document: { ...index.document, ...override } }
            }
            return index
        })
    return result
}

const checkDocumentUriAndIdMatch = (indexData, id) => {
    return indexData.document.uri.indexOf(id) > -1
}

export default documentIndexReducers