import React, { useEffect, useState } from "react";
import './TabContentLoginEIdEasy.scss';
import {
  Form,
  Modal,
  // Button,
  Select,
} from "antd";
import '@eid-easy/eideasy-widget';
import { connect } from "react-redux";
import { authenticate, storeAccessToken } from "../../../../../store/modules/authentication/actions";
import config from '../../../../../config'
import LoaderWithBackdrop from "../../../../components/LoaderWithBackdrop";
import { externalUserLogin } from "../../../../../services/keycloak";


const TabLoginEIdEasy = (props) => {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const code = urlSearchParams.get('code');
    if (code) {
      authenticate(code)
    }
    initWidget();
  }, [])

  const initWidget = () => {
    const widgetHolder = document.getElementById('widgetHolder');
    const eidEasyWidget = document.createElement('eideasy-widget');
    // TODO: move config to BE
    const settings = {
      clientId: config.eIdEasyClientId, // Required
      countryCode: 'LT', // Required, ISO 3166  two letter country code
      redirectUri: 'http://localhost:3000', // Required
      apiEndpoints: {
        identityStart: () => `${config.middlewareUrl}/api/eideasy/v1/identity/start`, // Required
        identityFinish: () => `${config.middlewareUrl}/api/eideasy/v1/identity/complete`, // Requried
      },
      language: 'en',
      enabledMethods: {
        // identification: 'all',
        identification: [
          'ee-id-login',
          'lv-id-login',
          'be-id-login',
          'fi-id-login',
          'lt-id-login',
          'pt-id-login',
          'rs-id-login',
          'lt-mobile-id',
          'mid-login',
          'smartid',
        ],
      },
      onSuccess: function ({ data }) {
        authenticate({
          code: data.idcode,
          firstName: data.firstname,
          lastName: data.lastname
        })
      },
      onFail: function () {
        // Modal.error({
        //   centered: true,
        //   title: "Something went wrong",
        //   content: <div>Authentication failed</div>,
        // });
      },
      // // additional settings for more specific use cases
      // enabledCountries: [''l'NO', 'BE', 'EE'],
    }

    Object.keys(settings).forEach(key => {
      eidEasyWidget[key] = settings[key];
    });

    widgetHolder?.appendChild(eidEasyWidget);
  }

  const authenticate = async (userData) => {
    setIsLoaderVisible(true);
    const { data } = await externalUserLogin(userData, props.countrySelected);
    if (!data.username) {
      Modal.error({
        centered: true,
        title: "Something went wrong",
        content: <div>Authentication failed</div>,
        onOk: () => {
          window.location.href = window.location.href.split('?')[0]
        }
      });
    } else {
      const { country, username, password } = data;
      props.authenticate(country, username, password)
    }
  }

  return <div className="TabContentLoginEIdEasy">
    {
      isLoaderVisible &&
      <LoaderWithBackdrop text="Loading..." />
    }
    <div className="container">
      <div className={`loginTab ${!props.countrySelected ? 'disable' : ''}`}>
        <div id="widgetHolder" className="widgetHolder"></div>
      </div>
    </div>
  </div>
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.loginReducer.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  authenticate: (country, username, password) =>
    dispatch(authenticate(country, username, password)),
  storeAccessToken: storeAccessToken(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TabLoginEIdEasy);
