import React, { useState } from "react";
import {
  Card,
  Empty,
  Spin,
  Divider,
  Descriptions,
  Button,
  Modal,
  Tooltip,
} from "antd";
import {
  CloudDownloadOutlined,
  EyeInvisibleOutlined,
  FileOutlined,
  CheckCircleOutlined,
  HomeOutlined
} from "@ant-design/icons";
import './index.scss';
import { getDocument } from "../../../services/document";
import { getCurrentUserId } from "../../../services/indexRecord/indexRecord";
import { getFileContentXslt, validateXml } from "../../../services/cloudStorage";
import config from "../../../config/defaultConfig";


const FileDescription = ({ loading, data }) => {
  const [loadingDocument, setLoading] = useState(false);
  const [validatingDocument, setValidationLoading] = useState(false);
  const { access, metaData } = data || {}

  const canGetDocument = access && access
    .find(({ userID }) => getCurrentUserId() === userID)?.policies
    .find(({ resources }) => {
      const temp = resources.join(';');
      return temp.indexOf('content') > -1 || temp.indexOf('<.*>') > -1
    })?.actions
    .find(action => action === 'get' || action === '<.*>')

  const getCurrentDocumentXslt = async (uri) => {
    const [fileId] = uri
      .split('/')
      .slice(-1)
    setLoading(true);
    try {
      const { data } = await getFileContentXslt(fileId)
      let wnd = window.open("", "_blank");
      if (wnd) {
        wnd.document.write(data)
      }
    } catch (err) {
      Modal.warning({
        centered: true,
        title: "Document validation",
        content: <div>{err.response.data}</div>,
      });
    } finally {
      setLoading(false);
    }
  }

  const validateCurrentDocument = async (uri) => {
    const [fileId] = uri
      .split('/')
      .slice(-1)
    setValidationLoading(true);
    try {
      const { data } = await validateXml(fileId)
      if (data.valid) {
        Modal.success({
          centered: true,
          title: "Document validation",
          content: <div>Document is valid</div>,
        });
      } else {
        Modal.warning({
          centered: true,
          title: "Document validation",
          content: <div>{data.error}</div>,
        });
      }
    } catch (err) {
      console.log(err)
      Modal.error({
        centered: true,
        title: "Document validation",
        content: <div>{err.response.data}</div>,
      });
    } finally {
      setValidationLoading(false);
    }
  }

  const getCurrentDocument = async (uri) => {
    try {
      setLoading(true);
      await getDocument(uri);
    } catch (err) {
      console.log(err)
      if (err.response && err.response.status == 404) {
        Modal.warning({
          centered: true,
          title: "Document retrival failed!",
          content: <div>Document not found</div>,
        });
      } else if (err.response && err.response.status == 401) {
        Modal.error({
          centered: true,
          title: "Access denied",
          content: <div>Do not have permission to access the file</div>,
          onOk: () => {
            window.location.reload();
          },
        });

        return;
      } else {
        Modal.error({
          centered: true,
          title: "Document retrival failed!",
          content: <div>{err.toString()}</div>,
        });
      }
      return;
    } finally {
      setLoading(false);
    }
  }


  const descriptionsStyle = { column: 1 };
  const dividerStyle: any = {
    orientation: "left",
    style: {
      color: "#333",
      fontWeight: "bold",
    },
  };

  const documentUrl = metaData ? `${config.fileStorageUrl}/files/${metaData.id}` : null
  
  return (
    <div className="IndexDescription">
      <Divider {...dividerStyle} orientation="left">
        Document
      </Divider>
      {
        loadingDocument &&
        <div className="loadingDocumentSpinner" >
          <Spin tip={"Getting document..."} size={"large"} />
        </div>
      }
      {
        validatingDocument &&
        <div className="loadingDocumentSpinner" >
          <Spin tip={"Validating document..."} size={"large"} />
        </div>
      }
      {
        loading ?
          <div style={{ textAlign: "center" }}>
            <Spin />
          </div>
          :
          <Card
            actions={[
              <Tooltip title="Home">
                <HomeOutlined
                  key="view"
                  onClick={() => window.location.href = '/'}
                />
              </Tooltip>,
            ]
            }
          >
            {
              metaData ?
                <Descriptions {...descriptionsStyle} size="small" bordered>
                  <Descriptions.Item label="Created At">
                    {new Date(metaData.createdAt).toLocaleString('lt')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Fingerprint">
                    {metaData.sha256}
                  </Descriptions.Item>
                  <Descriptions.Item label="File name">
                    {metaData &&
                      metaData.name || '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Type">
                    {
                      metaData &&
                      metaData.type || '-'
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label="Document Url">
                    {documentUrl}
                  </Descriptions.Item>
                  {
                    canGetDocument &&
                    <Descriptions.Item className="actions" label="Actions">
                      <Button
                        onClick={() => getCurrentDocument(documentUrl)}
                        type="dashed"
                        icon={<CloudDownloadOutlined />} />
                      {
                        metaData.name.endsWith('.xml') &&
                        <Button
                          type="dashed"
                          onClick={() => getCurrentDocumentXslt(documentUrl)}
                          icon={<FileOutlined />} />
                      }
                      {
                        metaData.name.endsWith('.xml') &&
                        <Button
                          type="dashed"
                          onClick={() => validateCurrentDocument(documentUrl)}
                          icon={<CheckCircleOutlined />} />
                      }
                    </Descriptions.Item>
                  }
                  {/* <Descriptions.Item label="Has access">
                    <EditableTagGroup
                      indexId={documentIdentifier ? documentIdentifier.value : null}
                      indexCountry={countryCode}
                      document={data[0].document}
                      items={data[0].document.access} />
                  </Descriptions.Item> */}
                  <Descriptions.Item label="Notes">
                    {
                      metaData &&
                      metaData.notes || '-'
                    }
                  </Descriptions.Item>
                </Descriptions> :
                <div className="noAccess" >
                  <EyeInvisibleOutlined /> {"You have no access to see this information"}
                </div>
            }
          </Card>
      }
    </div>
  );
};

export default FileDescription;
