const config = {
  middlewareUrl: 'https://middleware.index.topby.by/api/storage/v1',
  fileStorageUrl: 'https://api.cloud.corposign.net/v1/storage',
  countries: [
    {
      key: 'topby',
      name: 'MODERN TECHNOLOGIES OF TRADE LLC',
      apiUrl: 'https://api.index.topby.by/v1',
      logo64: '/img/flags/by-flag-64.png',
      sso: {
        url: 'https://sso.index.topby.by',
        realm: 'ecmr'
      }
    }
  ]
}
export default config
