const config = {
  middlewareUrl: 'https://middleware.index.ppl33-35.com/api/storage/v1',
  fileStorageUrl: 'https://api.cloud.corposign.net/v1/storage',
  countries: [
    {
      key: 'ppl33-35',
      name: 'LIMITED LIABILITY COMPANY PPL 33-35 Ltd.',
      apiUrl: 'https://api.index.ppl33-35.com/v1',
      logo64: '/img/flags/ua-flag-64.png',
      sso: {
        url: 'https://sso.index.ppl33-35.com',
        realm: 'ecmr'
      }
    }
  ]
}
export default config
