const valuesToIndex = (values) => {
    return {
      participantIdentifier: {
        scheme: values.participantSchemeId,
        value:
          values.participantIdentifierIcd +
          ":" +
          values.participantIdentifierRegisterCode,
      },
      documentIdentifier: {
        scheme: {
          id: values.documentSchemeId,
          value: values.documentSchemeValue,
        },
        value: values.documentId,
      },
      processIdentifier: {
        scheme: values.processIdentifierScheme,
        value: values.processIdentifierValue,
      },
      document: {
        expiredDateTime: values.documentExpiredDateTime
          ? values.documentExpiredDateTime.toISOString()
          : null,
        transport: values.transport,
        countryCode: values.documentCountryCode,
        uri: values.documentUri,
        fingerprint: values.documentFingerprint,
        content: values.documentContent,
        access: values.documentAccess,
        fileId: values.fileId,
      },
    };
  };

export default valuesToIndex