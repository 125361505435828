import React, { useEffect, useState } from "react";
import { getIndex } from "../../services/indexRecord/indexRecord";
import { connect } from "react-redux";
import {
  Checkbox,
  Divider,
  Col,
  Tabs,
  Row,
  Form,
  Input,
  Button,
  Modal,
  Card,
} from "antd";
import IndexDescription from "./IndexDescription/IndexDescription";
import PropTypes from "prop-types";
import { SearchOutlined } from "@ant-design/icons";
import { clearDocumentIndexAction, getDocumentIndexAction } from "../../store/modules/documentIndex/actions";
const { TabPane } = Tabs;

const cardStyle = {
  margin: 8,
  minWidth: "45vw",
  maxWidth: "70vw",
};

const row: any = {
  align: "middle",
  justify: "center",
  type: "flex",
  gutter: [8, 16],
  style: {
    width: "100%",
  },
};

const formItem = {
  style: {
    margin: 0,
  },
};

const GetIndexTab = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const url = new URL(window.location.href)
    const indexId = url.searchParams.get('indexId')
    if (indexId) {
      form.setFieldsValue({ id: indexId })
      onGetIndexData({ id: indexId, history: false })
    }
  }, [])


  const onGetIndexData = async (values) => {
    try {
      setLoading(true);
      await props.getDocumentIndex(props.country, values.id, values.history)
    } catch (err) {
      if (err.response && err.response.status == 404) {
        Modal.warning({
          centered: true,
          title: "Search failed!",
          content: <div>Index not found</div>,
        });
      } else if (err.response && err.response.status == 401) {
        Modal.error({
          centered: true,
          title: "Session ended",
          content: <div>Your session has ended. Please login again.</div>,
          onOk: () => {
            window.location.reload();
          },
        });
      } else {
        Modal.error({
          centered: true,
          title: "Search failed!",
          content: <div>{err.toString()}</div>,
        });
      }
    }
    setLoading(false);

    // setIndexData(values.history ? props.indexData : [props.indexData]);
    return;
  };

  return (
    <TabPane tab={'Get Index'} {...props}>
      <Card style={cardStyle}>
        <Form
          form={form}
          name="horizontal_login"
          // labelCol={{ span: 8}}
          // wrapperCol={{ span: 16 }}
          onFinish={onGetIndexData}
        >
          <Row {...row}>
            <Col {...{ span: 16, justify: "center" }}>
              <Form.Item
                {...formItem}
                label="Unique document index"
                name="id"
                rules={[
                  {
                    required: true,
                    message: "Please input unique document index (ex. test-1) !",
                  },
                ]}
              >
                <Input
                  style={{ minWidth: 150 }}
                  placeholder="Please enter unique document index"
                />
              </Form.Item>
            </Col>
          </Row>
          {props.withHistory ? (
            <div>
              <Row {...row}>
                <Col {...{ span: 16, justify: "center" }}>
                  <Form.Item
                    {...formItem}
                    name="history"
                    valuePropName="checked"
                    label="History"
                  >
                    <Checkbox />
                  </Form.Item>
                </Col>
              </Row>
              <Divider style={{ marginTop: 0 }} />
            </div>
          ) : (
            <Divider />
          )}

          <Row {...row}>
            <Col span={10}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                icon={<SearchOutlined />}
              >
                Search
              </Button>
            </Col>
            <Col span={6}>
              <Button
                style={{ width: "100%" }}
                onClick={() => {
                  form.resetFields();
                  props.clearDocumentIndex();
                }}
              >
                Clear
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card style={cardStyle}>
        <Row {...{ layout: "flex", justify: "center", align: "middle" }}>
          <IndexDescription
            loading={loading}
            data={props.indexData && (Array.isArray(props.indexData) ? props.indexData : [props.indexData])}
            onEdit={props.onEdit}
            onViewData={(data) => {
              Modal.info({
                centered: true,
                width: "55vw",
                title: "JSON data",
                content: JSON.stringify(data, null, "\t"),
                onOk() { },
              });
            }}
          />
        </Row>
      </Card>
    </TabPane>
  );
};

GetIndexTab.propTypes = {
  tab: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  withHistory: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  country: state.loginReducer.country,
  indexData: state.documentIndexReducer.indexData
});

const mapDispatchToProps = (dispatch) => ({
  getDocumentIndex: getDocumentIndexAction(dispatch),
  clearDocumentIndex: clearDocumentIndexAction(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(GetIndexTab);
