import { store } from '../../store'
import { addDocument, updateDocument, documentsStorageUrl } from '../document'
import { instance } from './instance'
import { Index } from './types'

export const findIndex = async (
  country: string,
  transportRegNumber: string,
  transportCountryCode: string
) => {
  return await instance.get(
    `/index?transport_reg_number=${transportRegNumber}&transport_country_code=${transportCountryCode.toUpperCase()}&country=${country}` +
    '&' + getLocationMetadata()
  )
}

export const getIndex = async (country: string, id: string, history = false) => {
  return await instance.get(
    `/index/${id}?history=${String(history)}&country=${country}` +
    '&' +
    getLocationMetadata()
  )
}

export const getIndexLog = async (country: string, id: string) => await instance
  .get(`/index/${id}/log?country=${country}`)

export const addIndex = async (country: string, index: Index) => {
  const newDocument: any = await addDocument(index)

  if(!newDocument?.data) return
  index.document.uri = `${documentsStorageUrl}/${newDocument?.data.id}`

  return instance.post(
    `/index?country=${country}` +
    '&' +
    getLocationMetadata(),
    index
  )
}

export const updateIndex = async (country: string, index: Index) => {
  const updatedDocument = await updateDocument(index)
  if (updatedDocument != null) {
    index.document.uri = `${documentsStorageUrl}/${updatedDocument.data.id}`
  }
  return await instance.put(
    `/index/${index.documentIdentifier.value}?country=${country}` +
    '&' +
    getLocationMetadata(),
    index
  )
}

const getLocationMetadata = () => {
  const state = store.getState()
  if (state.loginReducer && state.loginReducer.location) {
    return `longitude=${state.loginReducer.location.longitude}&latitude=${state.loginReducer.location.latitude}`
  }
  return ''
}

export const getCurrentUserId = () => {
  const state = store.getState()
  if (!state.loginReducer) return null
  return state.loginReducer.id
}

export const getCurrentUserInfo = () => {
  const state = store.getState()
  if (!state.loginReducer) return null

  return state.loginReducer
}
