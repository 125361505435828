import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from './views/pages/Home/Home'
import Login from './views/pages/Login/Login'
import PrivateRoute from './views/components/PrivateRoute'
import './App.less'
import { getCurrentUserInfo } from './services/indexRecord/indexRecord'
import { removeAccessTokens } from './store/modules/authentication/actions'

const App = () => {
  useEffect(() => {
    const { exp } = getCurrentUserInfo()
    if (exp * 1000 < Date.now()) {
      removeAccessTokens()
    }
  }, [])

  return <Router>
    <Switch>
      <PrivateRoute exact path="/" component={Home} />
      <Route path="/login" component={Login} />
      {/* <Redirect from="*" to="/" /> */}
    </Switch>
  </Router>
}

export default App
