import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store";
import * as serviceWorker from "./serviceWorker";
import { saveState } from "./views/utils/localStorage";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import throttle from "lodash/throttle";

// Sentry.init({
//   dsn: "https://e8fddb1ee9db45ab9e67c9ad30c1fade@sentry.chain.sis.lt/12",
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000)
);

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store} >
    <App />
  </Provider>
  // </React.StrictMode>,
  ,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
