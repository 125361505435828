import axios from "axios";
import config from '../../config'
import { removeAccessTokens } from "../../store/modules/authentication/actions";

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (!error.response) return Promise.reject(error);

        const { status } = error.response;
        if (status === 401) {
            removeAccessTokens();
        }
        return Promise.reject(error);
    }
);

export const instance = axios.create({
    baseURL: `${config.middlewareUrl}/api/keycloak/v1`,
});

export const createInstance = (url) => {
    const temp = axios.create({
        baseURL: url,
    });
    temp.defaults.headers.common["Authorization"] = instance.defaults.headers.common["Authorization"]
    return temp
}

export const setAccessToken = (token) => {
    if (token) {
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
        delete instance.defaults.headers.common["Authorization"];
    }
}