import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Col,
  Tabs,
  Row,
  Form,
  Input,
  Button,
  Modal,
  Card,
  Table,
  Spin,
  Tag,
  Space,
  Tooltip,
} from "antd";
import PropTypes from "prop-types";
import { getCountryColor } from "../utils/color";
import { getIndexLog } from "../../services/indexRecord/indexRecord";
import CertificateDisplay from "./CertificateDisplay/CertificateDisplay";
import {
  ReadOutlined,
  EnvironmentOutlined,
  EditOutlined,
  PlusOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import MapContainer from "./MapContainer";
import { Certificate } from "@fidm/x509";
const { TabPane } = Tabs;

const cardStyle = {
  margin: 8,
  minWidth: "45vw",
  maxWidth: "70vw",
};

const row: any = {
  align: "middle",
  justify: "center",
  type: "flex",
  gutter: [8, 16],
  style: {
    width: "100%",
  },
};

const formItem = {
  style: {
    margin: 0,
  },
};

const GetAccessLogTab = (props) => {
  const [loading, setLoading] = useState(false);
  const [accessLogData, setAccessLogData] = useState([]);
  const [users, setUsers] = useState([]);

  const columns: any = [
    {
      title: "Date",
      dataIndex: "dateTime",
      key: "date",
      render: (text) => <a>{text}</a>,
      sorter: (a, b) => new Date(a.dateTime) > new Date(b.dateTime),
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: ["descend"],
    },
    {
      title: "AP",
      dataIndex: "mspid",
      key: "ap",
      render: (mspid) => {
        let countryCode = mspid;
        let color = getCountryColor(countryCode);
        return (
          <Tag color={color} key={mspid}>
            {countryCode}
          </Tag>
        );
      },
      filters: [...accessLogData
        .map(({ mspid }) => mspid)]
        .map(mspid => ({
          text: mspid,
          value: mspid,
        })),
      onFilter: (value, record) => record.mspid === value,
    },
    {
      title: "User ID",
      dataIndex: "userId",
      key: "userId",
      render: (userId) => {
        return userId.substring(0, 37) + (userId.length > 36 ? "..." : "");
      },
      onFilter: (value, record) => record.userId === value,
      filters: users
        ? Object.keys(users).map((userId) => ({
          text: userId,
          value: userId,
        }))
        : null,
    },
    {
      title: "Member Type",
      dataIndex: "memberType",
      key: "memberType",
      render: (memberType) => {
        return <Tag
          color={
            memberType === "government"
              ? "blue"
              : "volcano"
          }
        >
          {memberType.charAt(0).toUpperCase() + memberType.slice(1)}
        </Tag>
      },
      onFilter: (value, record) => record.memberType === value,
      filters: [...accessLogData
        .map(({ memberType }) => memberType)]
        .map((memberType: string) => ({
          text: memberType.charAt(0).toUpperCase() + memberType.slice(1),
          value: memberType,
        })),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (action) => {
        switch (action.toLowerCase()) {
          case "read":
            return <Tag icon={<ReadOutlined />}>Read</Tag>;
          case "update":
            return <Tag icon={<EditOutlined />}>Update</Tag>;
          case "create":
            return <Tag icon={<PlusOutlined />}>Create</Tag>;
          default:
            return action;
        }
      },
      onFilter: (value, record) => record.action === value,
      filters: [
        {
          text: "Read",
          value: "read"
        },
        {
          text: "Update",
          value: "update"
        },
        {
          text: "Create",
          value: "create"
        }
      ],
    },
    {
      title: "Metadata",
      key: "metadata",
      dataIndex: "metadata",
      render: (metadata) => {
        return (
          <Space>
            <Tooltip title="Certificate">
              <Button
                onClick={() => {
                  Modal.info({
                    maskClosable: true,
                    centered: true,
                    width: "55vw",
                    title: "Certificate",
                    content: <CertificateDisplay pem={metadata.certificate} />,
                    onOk() { },
                  });
                }}
              >
                <KeyOutlined />
              </Button>
            </Tooltip>
            {metadata.location ? (
              <Tooltip title="Location">
                <Button
                  onClick={() => {
                    Modal.info({
                      width: "62vh",
                      maskClosable: true,
                      centered: true,
                      content: <MapContainer {...{ location: metadata.location }} />,
                      okText: "Close",
                      okType: "default",
                    });
                  }}
                >
                  <EnvironmentOutlined />
                </Button>
              </Tooltip>
            ) : (
              <></>
            )}
          </Space>
        );
      },
    },
  ];

  const onGetAccessLogData = async (values) => {
    let response: any = null;
    try {
      setLoading(true);
      response = await getIndexLog(props.country, values.id);
    } catch (err) {
      setLoading(false);
      if (err.response.status == 404) {
        Modal.warning({
          centered: true,
          title: "Search failed!",
          content: <div>Index not found</div>,
        });
      } else if (err.response && err.response.status == 401) {
        Modal.error({
          centered: true,
          title: "Session ended",
          content: <div>Your session has ended. Please login again.</div>,
          onOk: () => {
            window.location.reload();
          },
        });

        return;
      } else {
        Modal.error({
          centered: true,
          title: "Search failed!",
          content: <div>{err.toString()}</div>,
        });
      }
      return;
    }
    setLoading(false);
    setUsers(response.data.userIdentities);

    setAccessLogData(
      response.data.transactions
        ? response.data.transactions.map((tx) => {
          const cert = Certificate.fromPEM(Buffer.from(response.data.userIdentities[tx.userId].certificate, "utf-8"));

          let blockchainExtension: any = cert.extensions.find(
            (ext) => ext.oid === "1.2.3.4.5.6.7.8.1"
          );
          if (blockchainExtension !== undefined) {
            blockchainExtension = JSON.parse(blockchainExtension.value.toString())
              .attrs;
          }

          const data: any = {
            metadata: {
              certificate:
                response.data.userIdentities[tx.userId].certificate,
            },
            mspid: response.data.userIdentities[tx.userId].mspid,
            userId: tx.userId,
            dateTime: tx.dateTime,
            action: tx.actionType,
            memberType: blockchainExtension.member_type
          };
          if (tx.metadata && tx.metadata.location) {
            data.metadata.location = tx.metadata.location;
          }
          return data;
        })
        : null
    );
  };

  return (
    <TabPane {...props}>
      <Card style={cardStyle}>
        <Form
          name="horizontal_login"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onGetAccessLogData}
        >
          <Row {...row}>
            <Col span={16}
            // justify="center"
            >
              <Form.Item
                {...formItem}
                label="Unique document index"
                name="id"
                rules={[
                  {
                    required: true,
                    message: "Please input unique document index (ex. test-1) !",
                  },
                ]}
              >
                <Input placeholder="Please enter unique document index" />
              </Form.Item>
            </Col>
          </Row>
          <Row {...row}>
            <Col span={12}>
              <Button
                disabled={loading}
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Search
              </Button>
            </Col>
            <Col span={4}>
              <Button
                style={{ width: "100%" }}
                onClick={() => setAccessLogData([])}
              >
                Clear
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card style={cardStyle}>
        <Row {...{ layout: "flex", justify: "center", align: "middle" }}>
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <Spin />
            </div>
          ) : (
            <Table
              columns={columns}
              dataSource={accessLogData}
              style={{ width: "100%" }}
            />
          )}
        </Row>
      </Card>
    </TabPane>
  );
};

GetAccessLogTab.propTypes = {
  tab: PropTypes.string,
  withHistory: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  country: state.loginReducer.country,
});


export default connect(mapStateToProps)(GetAccessLogTab);
