import React from "react";
import { Route, Redirect } from "react-router-dom";
import { setAccessToken as setCloudStorageAT } from "../../services/cloudStorage";
import { setAccessToken as setIndexRecordAT } from "../../services/indexRecord";
import { setAccessToken as setKeycloakAT } from "../../services/keycloak/instance";


const PrivateRoute: any = ({ component: Component, isloggedIn, ...rest }) => {
  const accessToken = localStorage.getItem('access_token')
  setCloudStorageAT(accessToken)
  setIndexRecordAT(accessToken)
  setKeycloakAT(accessToken)
  return (
    <Route
      {...rest}
      render={(props: any) =>
        localStorage.getItem('access_token') ? (
          <Component {...props} />
        ) : (
          <Redirect to={`/login${window.location.search}`} />
        )
      }
    />
  );
}

export default PrivateRoute;
