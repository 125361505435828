import React, { useEffect, useState } from "react";
import './IndexDrawerForm.scss';
import { v4 as uuidv4 } from 'uuid';
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  DatePicker,
  Upload,
  Divider,
} from "antd";
import {
  UploadOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import sha256 from "crypto-js/sha256";
import CryptoJS from "crypto-js";
import moment from "moment";
import { COUNTRY_CODES } from "../../../../store/consts";
import EditableTagGroup from "../../EditableTagGroup/index";
import { getCurrentUserInfo } from "../../../../services/indexRecord/indexRecord";
import FileType from 'file-type';
import { connect } from "react-redux";
import { getFileAccessAction, getFileMetadataAction } from "../../../../store/modules/documentIndex/actions";
import { parseFileIdFromUri } from "../../../utils/fileAccess";

const { Option, OptGroup } = Select;

const truckCategoryCodes = [
  { name: "Truck", value: "3 31" },
  { name: "Van", value: "3 34" },
  { name: "Truck, reefer", value: "3 39" },
  { name: "Truck, tanker", value: "3 32" },
  { name: "Truck, dry bulk", value: "3 36" },
  { name: "Tiptanker", value: "3 35" },
];

const trailerCategoryCodes = [
  { name: "Trailer", value: "3 TE" },
  { name: "Semi Trailer", value: "3 SM" },
  { name: "Tank", value: "3 TA" },
  { name: "Boxcar", value: "3 BX" },
  { name: "Container", value: "3 CN" },
];

const IndexDrawerForm = (props) => {
  const currentUser = getCurrentUserInfo()
  const currentDate = new Date();
  const uniqueDocumentIdentifier = uuidv4();
  const currentISODate = `${currentDate.getFullYear()}${currentDate.getMonth() + 1}${currentDate.getDate()}`;
  const [retrivingMeta, setRetrivingMeta] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [form] = Form.useForm();
  const [formInitialized, setFormInitialized] = useState(false);
  const [filesList, setFilesList] = useState<any[]>([]);
  const [
    participantIdentifierIcdState,
    setparticipantIdentifierIcdState
  ] = useState(currentUser.ecmr.metadata.authority_icd);
  const [
    participantIdentifierRegisterCodeState,
    setparticipantIdentifierRegisterCodeState
  ] = useState(currentUser.ecmr.metadata.organization_id);

  useEffect(() => {
    setLoadingForm(retrivingMeta || props.loading)
  }, [retrivingMeta, props.loading])

  useEffect(() => {
    if (props.indexData) {
      retriveMetadata().then(() => {
        initializeForm()
      })
    }
  }, [props.indexData])

  const onFinish = async (values) => {
    await props.onSubmit(values);
    form.resetFields();
    setFilesList([]);
   }

  const arrayBufferToWordArray = (ab) => {
    const i8a = new Uint8Array(ab);
    const a: any = [];
    for (let i = 0; i < i8a.length; i += 4) {
      a.push(
        (i8a[i] << 24) | (i8a[i + 1] << 16) | (i8a[i + 2] << 8) | i8a[i + 3]
      );
    }
    return CryptoJS.lib.WordArray.create(a, i8a.length);
  };

  const retriveMetadata = async () => {
    if (props.indexData.document.fileMetadata) return
    setRetrivingMeta(true);

    const fileId = parseFileIdFromUri(props.indexData.document.uri)
    try {
      await Promise.all([
        props.getFileAccess(fileId),
        props.getFileMetadata(fileId)
      ])
    } catch (err) {
      console.log(err)
    } finally {
      setRetrivingMeta(false);
    }
  }

  const initializeForm = () => {
    var index = props.indexData;
    var expiredDateTime =
      index.document.expiredDateTime && index.document.expiredDateTime !== ""
        ? moment(index.document.expiredDateTime)
        : null;

    form.setFieldsValue({
      documentId: index.documentIdentifier.value,
      documentCountryCode: index.document.countryCode,
      documentUri: index.document.uri,
      documentExpiredDateTime: expiredDateTime,
      participantSchemeValue: index.participantIdentifier.value,
      participantIdentifierIcd: index.participantIdentifier.value.split(":")[0],
      participantIdentifierRegisterCode: index.participantIdentifier.value.split(":")[1],
      documentFingerprint: index.document.fingerprint,
      documentAccess: index.document.access,
      transport: index.document.transport,
      fileId: index.document.fileMetadata && index.document.fileMetadata.id
    });
    setparticipantIdentifierIcdState(index.participantIdentifier.value.split(":")[0])
    setparticipantIdentifierRegisterCodeState(index.participantIdentifier.value.split(":")[1])
    setFormInitialized(true);
  }

  if (formInitialized && !props.indexData) {
    setFormInitialized(false);
    form.resetFields();
  }


  const row: any = {
    align: "middle",
    justify: "center",
    type: "flex",
    gutter: [8],
    style: {
      width: "100%",
    },
  };

  const col: any = {
    span: 24,
  };

  return (
    <>
      <Drawer
        className={"IndexDrawerForm"}
        title={props.title ? props.title : "Create a new document index"}
        width={720}
        onClose={props.onClose}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div className="footer">
            <Button
              onClick={() => {
                form.resetFields();
                props.onClose();
              }}
              className={"cancel"}
              disabled={loadingForm}
            >
              Cancel
            </Button>
            <Button
              loading={loadingForm}
              onClick={form.submit}
              type="primary"
            >
              Submit
            </Button>
          </div>
        }
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          hideRequiredMark
        >
          <Divider style={{ marginTop: 0 }}>Identifiers</Divider>
          <Row {...row}>
            <Col {...col}>
              <Form.Item label="Participant Identifier" required={true}>
                <Row {...row}>
                  <Col span={12}>
                    <Form.Item
                      noStyle
                      name="participantSchemeId"
                      initialValue="iso6523-actorid-upis"
                      rules={[
                        {
                          required: true,
                          message: "Please enter scheme id!",
                        },
                      ]}
                    >
                      <Input
                        disabled={loadingForm}
                        className="schemeInput"
                        placeholder="Scheme" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      noStyle
                      name="participantSchemeValue"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter value!",
                    //   },
                    // ]}
                    >
                      <Input
                        disabled={true}
                        key={`${participantIdentifierIcdState}:${participantIdentifierRegisterCodeState}`}
                        defaultValue={`${participantIdentifierIcdState}:${participantIdentifierRegisterCodeState}`}
                        className="schemeInput"
                        placeholder="Value" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row {...row}>
            <Col {...col}>
              <Form.Item label="Document Identifier" required={true}>
                <Row {...row}>
                  <Col span={8}>
                    <Form.Item
                      noStyle
                      name="documentSchemeId"
                      initialValue="busdox-docid-qns"
                      rules={[
                        {
                          required: true,
                          message: "Please enter scheme id!",
                        },
                      ]}
                    >
                      <Input
                        disabled={loadingForm}
                        className={"schemaIDInput"}
                        placeholder="Scheme ID" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      noStyle
                      name="documentSchemeValue"
                      initialValue="eCMR v.1.0"
                      rules={[
                        {
                          required: true,
                          message: "Please enter scheme value!",
                        },
                      ]}
                    >
                      <Input
                        disabled={loadingForm}
                        className={"schemeValueInput"}
                        placeholder="Scheme Value"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      noStyle
                      required={true}
                      name="documentId"
                      rules={[
                        {
                          required: true,
                          message: "Please enter document ID!",
                        },
                      ]}
                    >
                      <Input
                        disabled={props.indexData !== null || loadingForm}
                        className={"documentIDInput"}
                        placeholder="ID" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row {...row}>
            <Col {...col}>
              <Form.Item label="Process Identifier" required={true}>
                <Row {...row}>
                  <Col span={12}>
                    <Form.Item
                      noStyle
                      initialValue="cenbii-procid-ubl"
                      name="processIdentifierScheme"
                      rules={[
                        {
                          required: true,
                          message: "Please enter scheme value!",
                        },
                      ]}
                    >
                      <Input
                        disabled={loadingForm}
                        className={"schemeInput"}
                        placeholder="Scheme" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      noStyle
                      initialValue="urn:www.cenbii.eu:profile:ecmr:ver1.0"
                      name="processIdentifierValue"
                      rules={[
                        {
                          required: true,
                          message: "Please enter scheme value!",
                        },
                      ]}
                    >
                      <Input
                        disabled={loadingForm}
                        className={"schemeInput"}
                        placeholder="Value" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Divider style={{ marginTop: 0 }}>Document</Divider>
          <Row gutter={16}>
            <Col span={18}>
              <Form.Item
                name="documentId"
                label="Document index"
                initialValue={currentUser.country + "-" + currentUser.ecmr.metadata.authority_icd + ":" + currentUser.ecmr.metadata.organization_id + "-" + currentISODate + "-" + uniqueDocumentIdentifier.toString().substring(0, 8)}
                rules={[{ required: true, message: "Please enter document index" }]}
              >
                <Input
                  disabled={props.indexData !== null || loadingForm}
                  style={{ width: "100%" }}
                  placeholder="countryCode-companycode-date yyyymmdd-doc number"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="documentCountryCode"
                label="Country Code"
                initialValue={currentUser.country}
                rules={[
                  {
                    required: true,
                    message: "Please select country code"
                  },
                ]}
              >
                <Select
                  disabled={loadingForm}
                  showSearch
                  placeholder="Country Code"
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >

                  {
                    COUNTRY_CODES.map((c) => (
                      <Option key={c.toLowerCase()} value={c.toLowerCase()}>{c.toUpperCase()}</Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {props.indexData ? (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="documentExpiredDateTime"
                  label="Expired Date Time"
                >
                  <DatePicker
                    disabled={loadingForm}
                    showTime
                    style={{ width: "100%" }}
                    getPopupContainer={(trigger: any) => trigger.parentElement}
                  />
                </Form.Item>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Divider plain>Company</Divider>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="participantIdentifierIcd"
                label="ISO 6523 ICD"
                initialValue={currentUser.ecmr.metadata.authority_icd}
                rules={[
                  { required: true, message: "Please enter ISO 6523 ICD" },
                  {
                    len: 4,
                    message: "ISO 6523 ICD must be exactly of 4 characters",
                  },
                  {
                    pattern: new RegExp("[0-9]{4}"),
                    message: "ISO 6523 ICD can only contain digits",
                  },
                ]}
              >
                <Input
                  disabled={loadingForm}
                  onChange={(ev) => setparticipantIdentifierIcdState(ev.target.value)}
                  placeholder="Please enter ICD" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="participantIdentifierRegisterCode"
                label="Register Code"
                initialValue={currentUser.ecmr.metadata.organization_id}
                rules={[
                  { required: true, message: "Please enter register code" },
                ]}
              >
                <Input
                  disabled={loadingForm}
                  style={{ width: "100%" }}
                  onChange={(ev) => setparticipantIdentifierRegisterCodeState(ev.target.value)}
                  placeholder="Please enter register code"
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider plain>Document</Divider>
          <Row align="top" gutter={16}>
            <Upload
              disabled={loadingForm}
              className={"DocumentUpload"}
              fileList={filesList}
              multiple={false}
              showUploadList={{
                showRemoveIcon: false
              }}
              customRequest={({ file, onSuccess }) => {
                const temp: any = file;
                if (onSuccess) {
                  onSuccess({ message: "ok" }, temp);
                }
                setFilesList([file]);
              }}
              beforeUpload={async (file) => {
                let buffer: any = await new Promise((resolve) => {
                  const reader = new FileReader();
                  reader.readAsArrayBuffer(file);
                  reader.onload = () => resolve(reader.result);
                });
                const { ext } = (await FileType.fromBuffer(buffer)) || {}

                const data = new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });
                form.setFieldsValue({ documentContent: { name: file.name, data, type: ext } })

                form.setFieldsValue({ documentFingerprint: sha256(arrayBufferToWordArray(buffer)).toString() });
              }}
            >
              <div className="uploadContainer">
                <UploadOutlined className={"UploadIcon"} />
                <span>Click to upload</span>
              </div>
            </Upload>
          </Row>
          {
            props.indexData && props.indexData.document.uri &&
            <EditableTagGroup
              loading={loadingForm}
              key={props.indexData && props.indexData.document.access && props.indexData.document.access.length}
              indexId={props.indexData ? props.indexData.documentIdentifier.value : null}
              indexCountry={props.indexData.document ? props.indexData.document.countryCode : null}
              document={props.indexData ? props.indexData.document : {}}
              items={props.indexData ? props.indexData.document.access : []}
              label="Has access" />
          }
          <Row align="top" gutter={16}>
            <Form.Item hidden name="documentUri">
            </Form.Item>
            <Form.Item hidden name="documentContent">
            </Form.Item>
            <Form.Item hidden name="fileId">
            </Form.Item>
          </Row>
          <Row align="top" gutter={16}>
            <Col span={24}>
              <Form.Item
                // noStyle
                name="documentFingerprint"
                label="Fingerprint"
                rules={[
                  {
                    required: true,
                    message: "Please enter eCMR fingerprint",
                  },
                  {
                    len: 64,
                    message:
                      "Fingerprint must be of SHA256 format (64 characters)",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  disabled
                // placeholder="Please enter eCMR fingerprint"
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider plain>Transport</Divider>

          <Form.List name="transport">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field: any) => (
                    <Row gutter={4} key={field.key}>
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          name={[field.name, "registrationNumber"]}
                          fieldKey={[field?.fieldKey, "registrationNumber"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing registration number",
                            },
                            {
                              pattern: new RegExp("[0-9]+"),
                              message:
                                "Only digits and uppercase letter allowed",
                            },
                          ]}
                        >
                          <Input
                            disabled={loadingForm}
                            style={{ width: "100%" }}
                            placeholder="Transport Registration Number"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item
                          {...field}
                          name={[field.name, "categoryCode"]}
                          fieldKey={[field.fieldKey, "categoryCode"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing category code",
                            },
                          ]}
                        >
                          <Select
                            disabled={loadingForm}
                            style={{ width: "100%" }}
                            placeholder="Category Code"
                          >
                            <OptGroup key="truck" label="Truck">
                              {truckCategoryCodes.map((entry, index) => (
                                <Option key={index} value={entry.value}>
                                  {entry.name}
                                </Option>
                              ))}
                            </OptGroup>
                            <OptGroup key="trailer" label="Trailer">
                              {trailerCategoryCodes.map((entry, index) => (
                                <Option
                                  key={truckCategoryCodes.length + index}
                                  value={entry.value}
                                >
                                  {entry.name}
                                </Option>
                              ))}
                            </OptGroup>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          {...field}
                          name={[field.name, "countryCode"]}
                          fieldKey={[field.fieldKey, "countryCode"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing country code",
                            },
                          ]}
                        >
                          <Select
                            disabled={loadingForm}
                            showSearch
                            placeholder="Country Code"
                            filterOption={(input, option) =>
                              JSON.stringify(option?.children)
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {COUNTRY_CODES.map((c) => (
                              <Option key={c.toLowerCase()} value={c.toLowerCase()}>
                                {c.toUpperCase()}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={1}>
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </Col>
                    </Row>
                  ))}

                  <Row>
                    <Col span={24}>
                      <Form.Item>
                        <Button
                          disabled={loadingForm}
                          type="dashed"
                          onClick={() => {
                            add();
                          }}
                          block
                        >
                          <PlusOutlined /> Add Transport
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              );
            }}
          </Form.List>
        </Form>
      </Drawer>
    </>
  );
};

IndexDrawerForm.propTypes = {
  title: PropTypes.string,
  indexData: PropTypes.object,
  loading: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getFileAccess: getFileAccessAction(dispatch),
  getFileMetadata: getFileMetadataAction(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexDrawerForm);
