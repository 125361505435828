import { instance } from './index'
import { createInstance } from './instance'
import config from '../../config'

export interface IFileBody {
  file: string | Blob,
  name: string,
  type: string,
  format: string,
  notes: string
}

export const createFile = (body: IFileBody) => {
  const { file, name, type, format } = body
  const form = new FormData()
  form.append('file', file, name)
  form.append('type', type)
  form.append('format', format)
  const requestInstance = createInstance(`${config.fileStorageUrl}`)

  return requestInstance.post('/files', form)
}

export const updateFile = async (fileID: string, body: IFileBody) => {
  return await instance.put(`/files/${fileID}`, body)
}

export const getFile = async (fileID: string, accessToken?: string) => {
  return await instance.get(`/files/${fileID}${accessToken ? `?accessToken=${accessToken}` : ''}`)
}

export const getFileContent = async (fileID: string, accessToken?: string) => {
  return await instance.post(`/files/${fileID}/content${accessToken ? `?accessToken=${accessToken}` : ''}`)
}

export const getFileContentXslt = async (fileID: string) => {
  return await instance.get(`/files/${fileID}/transformation`)
}

export const validateXml = async (fileID: string) => {
  return await instance.get(`/files/${fileID}/validation`)
}

export const getAllFiles = async () => {
  return await instance.get('/ files')
}
